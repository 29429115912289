var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ul',{staticClass:"list-reset flex flex-col mb-4"},[_c('li',{staticClass:"flex items-center px-2 py-2 w-full bg-gray-300 rounded shadow mb-4 focus:outline-none border border-transparent focus:border-blue-500"},[_c('div',{staticClass:"w-2/12 font-normal text-sm flex flex-col ml-3 tracking-wide"},[_vm._v(" Business Detail ")]),_c('div',{staticClass:"font-normal text-sm flex flex-col ml-3 tracking-wide",class:{
          'w-2/12': _vm.getStatus === 'rejected',
          'w-3/12': _vm.getStatus !== 'rejected'
        }},[_vm._v(" User Details ")]),_c('div',{staticClass:"font-normal text-sm flex flex-col ml-3 tracking-wide",class:{
          'w-2/12': _vm.getStatus === 'rejected',
          'w-3/12': _vm.getStatus !== 'rejected'
        }},[_vm._v(" Company Subdomain ")]),_c('div',{staticClass:"font-normal text-sm flex flex-col ml-3 tracking-wide"},[_vm._v(" Status ")]),_c('div',{staticClass:"w-2/12 font-normal flex text-sm items-end flex-col ml-3 tracking-wide"},[_vm._v(" Applied Date ")]),(_vm.getStatus === 'rejected')?_c('div',{staticClass:"w-2/12 font-normal flex text-sm items-end flex-col ml-3 tracking-wide"},[_vm._v(" Rejected Date ")]):_vm._e(),_c('div',{staticClass:"w-1/12 font-normal flex text-sm items-end flex-col ml-3 tracking-wide",class:{ 'pr-4': _vm.getStatus === 'rejected' }},[_vm._v(" Action ")])]),_vm._l((_vm.applications.data),function(request){return _c('li',{key:request.id,staticClass:"flex items-center px-4 py-2 w-full bg-white rounded shadow h-20 mb-4 focus:outline-none border border-transparent focus:border-blue-500",attrs:{"tabindex":"0"}},[_c('div',{staticClass:"w-2/12 font-normal text-lg flex flex-col ml-3 tracking-wide truncate",attrs:{"title":request.business_details.name}},[_vm._v(" "+_vm._s(request.business_details.name)+" "),_c('span',{staticClass:"text-sm truncate"},[_vm._v(_vm._s(request.business_details.email))])]),_c('div',{staticClass:"font-normal text-lg flex flex-col ml-3 tracking-wide truncate",class:{
          'w-2/12': _vm.getStatus === 'rejected',
          'w-3/12': _vm.getStatus !== 'rejected'
        }},[_vm._v(" "+_vm._s(request.user_details.name)+" "),_c('span',{staticClass:"text-sm truncate"},[_vm._v(_vm._s(request.user_details.email))])]),_c('div',{staticClass:"font-normal text-lg flex flex-col ml-3 tracking-wide truncate",class:{
          'w-2/12': _vm.getStatus === 'rejected',
          'w-3/12': _vm.getStatus !== 'rejected'
        }},[_vm._v(" "+_vm._s(request.company_domain)+" ")]),_c('div',{staticClass:"font-normal ml-3"},[_c('div',{staticClass:"border ml-2 mt-4 md:mt-0 flex flex-shrink-0 text-sm border-gray-200 rounded"},[_c('div',{staticClass:"px-2 py-1 relative rounded",class:_vm.getStatusClassWrapper(request.status)},[_c('span',{staticClass:"status pl-4 inline-flex rounded text-sm font-medium capitalize",class:_vm.getStatusClass(request.status)},[_vm._v(" "+_vm._s(request.status)+" ")])])])]),_c('div',{staticClass:"w-2/12 font-normal flex items-end flex-col ml-3 tracking-wide"},[_c('span',{staticClass:"text-gray-600 text-sm"},[_vm._v("Applied On")]),_c('span',{},[_vm._v(" "+_vm._s(request.applied_on)+" ")])]),(_vm.getStatus === 'rejected')?_c('div',{staticClass:"w-2/12 font-normal flex items-end flex-col ml-3 tracking-wide"},[_c('span',{staticClass:"text-gray-600 text-sm"},[_vm._v("Rejected On")]),_c('span',{},[_vm._v(" "+_vm._s(request.rejected_on)+" ")])]):_vm._e(),_c('div',{staticClass:"w-1/12 font-normal flex items-end flex-col ml-3 tracking-wide"},[_c('div',{staticClass:"flex items-center"},[_c('button',{staticClass:"focus:outline-none focus:shadow hover:shadow border border-blue-400 text-blue-700 tracking-wide text-sm py-1 px-4 rounded",on:{"click":function($event){return _vm.openDetail(request)}}},[_vm._v(" View ")])])])])})],2),(_vm.applications.meta && _vm.applications.meta.total > 1)?_c('RamroPagination',{staticClass:"mb-6 mt-6",attrs:{"current":_vm.applications.meta.current_page,"total":_vm.applications.meta.last_page},on:{"update":function($event){return _vm.$router.push({ query: { page: $event.page } })}}}):_vm._e(),_c('div',[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }